import React, { Component } from 'react';
import {IndexLinkContainer} from 'react-router-bootstrap';

class ArticleListing extends Component {
  render () {
    return (
      <IndexLinkContainer to={`/news/${this.props.id}`}>
        <div className="article_listing">
          <div className="article_listing_text">
            <p className="article_type">Article</p>
            <p className="article_title">{this.props.title}</p>
            <p className="article_desc"><span className="normal">{this.props.brief_desc}</span></p>
            <p className="article_author">{this.props.author}</p>
          </div>
          <div className="article_listing_img">
            <img alt="Article Banner" className="article_image" src={this.props.image_url}/>
          </div>
        </div>
      </IndexLinkContainer>
    )
  }
}

export default ArticleListing;
