import React, { Component } from 'react';

class OpEdListing extends Component {
  open = (link) => {
    window.open(link, "articlepost");
  }

  render () {
    return (
      <div className="article_listing" onClick={() => this.open(this.props.link)}>
        <div className="article_listing_text">
          <p className="article_type">Publication/Published Article</p>
          <p className="article_title">{this.props.title}</p>
          <p className="article_desc">{this.props.desc}</p>
        </div>
        <div className="article_listing_img">
          <img alt="Publication Banner" className="article_image" src={this.props.image_url}/>
        </div>
      </div>
    )
  }
}

export default OpEdListing;
