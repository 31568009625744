import React, { Component } from 'react';
import {IndexLinkContainer} from 'react-router-bootstrap';
import { MdBubbleChart } from "react-icons/md";
import Fade from 'react-reveal/Fade';

class HomeAbout extends Component {
  render () {
    return (
      <div className="homeabout">
        <div className="home_about_bg"></div>
        <div className="home_about_diag"></div>
        <div className="content_container home_about_content">
          <Fade down>
            <div className="heading_icon_container">
              <MdBubbleChart className="heading_icon"/>
              <h2 className="heading"> {this.props.title}</h2>
            </div>
          </Fade>
          <Fade delay={200}>
            <p>{this.props.text}</p>
          </Fade>
          <Fade delay={200}>
            <IndexLinkContainer to="/about">
              <div className="button home_services_button">
                <p className="button_text">Read More about Axis Law</p>
              </div>
            </IndexLinkContainer>
          </Fade>
        </div>
      </div>
    )
  }
}

export default HomeAbout;
