import React, { Component } from 'react';
import {IndexLinkContainer} from 'react-router-bootstrap';

class AnnListing extends Component {
  render () {
    return (
      <IndexLinkContainer to={`/announcements/${this.props.id}`}>
        <div className="article_listing">
          <div className="article_listing_text">
            <p className="article_type">Announcement</p>
            <p className="article_title">{this.props.title}</p>
            <p className="article_desc"><span className="normal">{this.props.brief_desc}</span></p>
          </div>
          <div className="article_listing_img">
            <img alt="Announcement Banner" className="article_image" src={this.props.image_url}/>
          </div>
        </div>
      </IndexLinkContainer>
    )
  }
}

export default AnnListing;
