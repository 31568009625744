import React, { Component } from 'react';
import {IndexLinkContainer} from 'react-router-bootstrap';
import Particles from 'react-particles-js';
import Fade from 'react-reveal/Fade';
import { MdBubbleChart } from "react-icons/md";

class HomeNews extends Component {
  open = (link) => {
    window.open(link, "articlepost");
  }
  render () {
    return (
        <div>
          <div className="heading_icon_container">
            <MdBubbleChart className="heading_icon"/>
            <h2 className="heading"> Latest News & Updates</h2>
          </div>
          <div className="home_news_container">
            <div className="home_news_colored_portion"></div>
            <div className="content_container home_news_content">
              <IndexLinkContainer to={`/announcements/${this.props.banner_article._id}`}>
                <div className="home_news_banner_section">
                  <Particles className="absolute_particles"
                    params={{
                      "particles": {
                          "number": {
                              "value": 8
                          },
                          "size": {
                              "value": 2
                          },
                          "line_linked": {
                              "enable": false
                          },
                          "move": {
                              "random": true,
                              "speed": 1,
                              "direction": "top",
                              "out_mode": "out"
                          }
                      }}}
                    />
                    <Fade down delay={250}>
                      <p className="home_news_banner_written_by">NEWS</p>
                    </Fade>
                    <Fade delay={500}>
                      <p className="home_news_banner_title">{this.props.banner_article.title}</p>
                    </Fade>
                    <Fade delay={500}>
                      <p style={{color: 'white'}} className="home_news_banner_desc">{this.props.banner_article.brief_desc}</p>
                    </Fade>
                    <Fade up delay={250}>
                      <p className="home_news_banner_written_by">{this.props.banner_article.writer}</p>
                    </Fade>
                </div>
                </IndexLinkContainer>
              <div className="home_news_other_section">
                <div className="home_news_other" onClick={() => this.open(this.props.publication.link)}>
                  <Fade down delay={500}>
                    <p className="home_news_other_categ">Publication</p>
                  </Fade>
                  <Fade delay={700}>
                    <p className="home_news_other_title">{this.props.publication.title}</p>
                    <p className="home_news_other_desc">{this.props.publication.description}</p>
                  </Fade>
                </div>
                <IndexLinkContainer to={`/news/${this.props.article._id}`}>
                  <div className="home_news_other">
                    <Fade down delay={500}>
                      <p className="home_news_other_categ">Article</p>
                    </Fade>
                    <Fade delay={700}>
                      <div>
                        <p className="home_news_other_title">{this.props.article.title}</p>
                      </div>
                      <p className="home_news_other_desc">{this.props.article.brief_desc}</p>
                    </Fade>
                  </div>
                </IndexLinkContainer>
              </div>
            </div>
          </div>
        </div>
    )
  }
}

export default HomeNews;
