import React, { Component } from 'react';
import axios from 'axios';
import RingLoader from 'react-spinners/RingLoader';
import Fade from 'react-reveal/Fade';
import NavBar from '../components/navbar';
import SectionTitle from '../components/sectiontitle';
import ArticleListing from '../components/article';
import AnnListing from '../components/announce';
import OpEdListing from '../components/publication';
import Footer from '../components/footer';
import SimpleSlider from "../components/slickNews";

class News extends Component {
  state = {
    loading: true,
  }

  componentDidMount () {
    const companyPromise = axios.get('https://cms-axis.herokuapp.com/companyinfos');
    const articlesPromise = axios.get('https://cms-axis.herokuapp.com/blogposts?_sort=updatedAt:desc&_limit=9');
    const opEdPromise = axios.get('https://cms-axis.herokuapp.com/publications?_sort=updatedAt:desc&_limit=9');
    const announcePromise = axios.get('https://cms-axis.herokuapp.com/announcements?_sort=updatedAt:desc&_limit=5');
    const parent = this;

    Promise.all([companyPromise, articlesPromise, opEdPromise, announcePromise]).then(function(values) {
      parent.setState({
        companyInfo: values[0].data[values[0].data.length-1],
        articles: values[1].data,
        latestArticle: values[1].data[0],
        publications: values[2].data,
        latestPub: values[2].data[0],
        announcements: values[3].data,
        latestAnn: values[3].data[0],
        loading: false
      })
    });
  }

  render () {
    return (
      <div>
        {this.state.loading === true ?
          <div className="loading">
            <RingLoader
              sizeUnit={"px"}
              size={50}
              color={'#222F55'}
              loading={this.state.loading}
            />
          </div>
          :
          <div>
            <Fade>
              <div>
                <NavBar color='#FFFFFF' textcolor='#777'/>
                <SectionTitle title="News & Updates"/>
                <div className="content_container home_about_content">
                <SimpleSlider latestArticle={this.state.latestArticle} latestPub={this.state.latestPub} latestAnn={this.state.latestAnn}/>
                  {this.state.announcements.length === 0 ?
                      <p className="no_jobs">There are no announcements added right now</p>
                    :
                    <div className="oped_container">
                      {this.state.announcements.map((announcement, index) => (
                        <AnnListing key={index} title={announcement.title} desc={announcement.detailed_description} id={announcement._id} image_url={announcement.image_url} brief_desc={announcement.brief_desc} firstname={announcement.new_partner_first_name} surname={announcement.new_partner_last_name}/>
                      ))}
                    </div>
                  }
                  {this.state.articles.length === 0 ?
                      <p className="no_jobs">There are no articles added right now</p>
                    :
                    <div className="articles_container">
                      {this.state.articles.map((article, index) => (
                        <ArticleListing key={index} title={article.title} author={article.writer} disclaimer={article.disclaimer} link={article.pdf_url} image_url={article.image_url} brief_desc={article.brief_desc} id={article._id}/>
                      ))}
                    </div>
                  }
                  {this.state.publications.length === 0 ?
                      <p className="no_jobs">There are no op-eds or publications added right now</p>
                    :
                    <div className="oped_container">
                      {this.state.publications.map((publication, index) => (
                        <OpEdListing key={index} title={publication.title} desc={publication.description} link={publication.link} id={publication._id} image_url={publication.image_url}/>
                      ))}
                    </div>
                  }
                </div>
                <Footer name={this.state.companyInfo.Name} locations={this.state.companyInfo.addresses} phones={this.state.companyInfo.phones} emails={this.state.companyInfo.emails} hours={this.state.companyInfo.office_hours} />
              </div>
            </Fade>
          </div>
        }
      </div>
    )
  }
}

export default News;
