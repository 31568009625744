import React, { Component } from 'react';
import axios from 'axios';
import RingLoader from 'react-spinners/RingLoader';
import Fade from 'react-reveal/Fade';
import NavBar from '../components/navbar';
import SectionTitle from '../components/sectiontitle';
import Partners from '../components/partners';
import Associates from '../components/associates';
import Footer from '../components/footer';

class Team extends Component {
  state = {
    loading: true,
    current_section: "partner_display"
  }

  componentDidMount () {
    const companyPromise = axios.get('https://cms-axis.herokuapp.com/companyinfos');
    const associatePromise = axios.get('https://cms-axis.herokuapp.com/associates?_sort=surname:asc');
    const partnerPromise = axios.get('https://cms-axis.herokuapp.com/partners?_sort=updatedAt:desc');

    const parent = this;

    Promise.all([companyPromise, associatePromise, partnerPromise]).then(function(values) {
      parent.setState({
        companyInfo: values[0].data[values[0].data.length-1],
        associates: values[1].data,
        partners: values[2].data,
        loading: false
      })
    });
  }

  toggleSection = (section_to, section_from) => {
    var changeTo = document.getElementById(section_to);
    var changeFrom = document.getElementById(section_from);
    changeTo.classList.add('active');
    changeFrom.classList.remove('active_team_nav');

    this.setState({
      current_section: section_to,
    })
  }

  setSection = (id) => {
    var button = document.getElementById(id);
    var elems = document.querySelectorAll(".custom_tabs div");

    [].forEach.call(elems, function(el) {
        el.classList.remove("active");
    });

    this.setState({current_section: id});
    button.classList.add("active");
  }

  render () {
    return (
      <div>
        {this.state.loading === true ?
          <div className="loading">
            <RingLoader
              sizeUnit={"px"}
              size={50}
              color={'#222F55'}
              loading={this.state.loading}
            />
          </div>
          :
          <div>
            <Fade>
              <div>
                <NavBar color='#FFFFFF' textcolor='#777'/>
                <SectionTitle title="Our Lawyers"/>
                <div className="content_container" style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                  <div className="custom_tabs">
                    <div className="active" id="partner_display" onClick={() => this.setSection("partner_display")}>Partners</div>
                    <div id="associates_display" onClick={() => this.setSection("associates_display")}>Associates</div>
                  </div>
                </div>
                {this.state.current_section === "partner_display" ?
                  <Partners partners={this.state.partners}/>
                :
                  <Associates associates={this.state.associates}/>
                }
                <Footer name={this.state.companyInfo.Name} locations={this.state.companyInfo.addresses} phones={this.state.companyInfo.phones} emails={this.state.companyInfo.emails} hours={this.state.companyInfo.office_hours} />
              </div>
            </Fade>
          </div>
        }
      </div>
    )
  }
}

export default Team;
