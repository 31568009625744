import React, { Component } from 'react';
import axios from 'axios';
import RingLoader from 'react-spinners/RingLoader';
import Fade from 'react-reveal/Fade';
import NavBar from '../components/navbar';
import Slider from '../components/slide';
import HomeServices from '../components/homeServices';
import HomeLocations from '../components/homelocations';
import HomeAbout from '../components/homeabout';
import HomeNews from '../components/homenews';
import Footer from '../components/footer';
import {IndexLinkContainer} from 'react-router-bootstrap';
import { IoIosArrowDropdownCircle } from "react-icons/io";
import { TiSocialTwitter } from "react-icons/ti";
import { TiSocialLinkedin } from "react-icons/ti";
import { TiSocialFacebook } from "react-icons/ti";
import TextyAnim from 'rc-texty';
import smoothscroll from 'smoothscroll-polyfill';

class Home extends Component {
  state = {
    loading: true,
  }

  twitter = () => {
    window.open("https://twitter.com/axislawpk", "TwitterProfile");
  }

  facebook = () => {
    window.open("https://www.facebook.com/axislawchambers/?_rdc=1&_rdr", "FacebookProfile");
  }

  linkedIn = () => {
    window.open("https://www.linkedin.com/company/hassan-&-rahim/", "LinkedInProfile");
  }

  scrollTo = () => {
    window.scrollTo({
      top: window.innerHeight,
      behavior: 'smooth'
    });
  }

  componentDidMount () {
    smoothscroll.polyfill();
    const companyPromise = axios.get('https://cms-axis.herokuapp.com/companyinfos');
    const slidesPromise = axios.get('https://cms-axis.herokuapp.com/slides');
    const bannerArticlePromise = axios.get('https://cms-axis.herokuapp.com/blogposts?_sort=updatedAt:desc&_limit=1');
    const opEdPromise = axios.get('https://cms-axis.herokuapp.com/publications?_sort=updatedAt:desc&_limit=1');
    const announcePromise = axios.get('https://cms-axis.herokuapp.com/announcements?_sort=updatedAt:desc&_limit=1');

    const parent = this;

    Promise.all([companyPromise, slidesPromise, bannerArticlePromise, opEdPromise, announcePromise]).then(function(values) {
      parent.setState({
        companyInfo: values[0].data[values[0].data.length-1],
        slides: values[1].data,
        article: values[2].data[0],
        publication: values[3].data[0],
        banner_article: values[4].data[0],
        loading: false
      })
    });
  }

  render () {
    return (
      <div>
        {this.state.loading === true ?
          <div className="loading">
            <RingLoader
              sizeUnit={"px"}
              size={50}
              color={'#222F55'}
              loading={this.state.loading}
            />
          </div>
          :
          <div>
            <Fade>
              <div>
                <NavBar color='#FFFFFF' textcolor='#777' navitems={['ABOUT US', 'SERVICES', 'PROJECTS', 'OUR TEAM']}/>
                <div style={{position: 'relative'}}>
                  <Slider slides={this.state.slides}/>
                  <div className="axis_law_banner_container">
                    <div className="color_overlay">
                    </div>
                    <div className="axis_law_banner">
                      <div className="content_container">
                        <TextyAnim className="axis_main_banner" delay={400} duration={1250}>
                          Axis Law
                        </TextyAnim>
                        <Fade delay={1500}>
                          <div className="axis_main_separator"></div>
                        </Fade>
                      </div>
                      <Fade delay={1500}>
                        <div className="home_scroll">
                          <div className="content_container" style={{display: 'flex', justifyContent: 'space-between'}}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                              <IoIosArrowDropdownCircle onClick={() => this.scrollTo()} style={{marginLeft: '5px', fontWeight: '600', color: '#F4E04D', cursor: 'pointer'}} size={24}/>
                            </div>
                            <div>
                              <TiSocialLinkedin onClick={() => this.linkedIn()} style={{marginLeft: '-3px'}} className="home_social" size={26}/>
                              <TiSocialTwitter onClick={() => this.twitter()} className="home_social" size={26}/>
                              <TiSocialFacebook onClick={() => this.facebook()} className="home_social" size={26}/>
                            </div>
                          </div>
                        </div>
                      </Fade>
                      <div className="home_social_bar"></div>
                    </div>
                  </div>
                </div>
                <HomeLocations locations={this.state.companyInfo.addresses}/>
                <HomeAbout title={this.state.companyInfo.home_about_title} text={this.state.companyInfo.home_about_description}/>
                <div className="content_container">
                  <IndexLinkContainer to="/about">
                    <div className="button home_services_button">
                      <p className="button_text">Read More</p>
                    </div>
                  </IndexLinkContainer>
                </div>
                <HomeServices services={this.state.companyInfo.capabilities} image={this.state.companyInfo.content_banner_url}/>
                <HomeNews banner_article={this.state.banner_article} publication={this.state.publication} article={this.state.article}/>
                <Footer name={this.state.companyInfo.Name} phones={this.state.companyInfo.phones} emails={this.state.companyInfo.emails} />
              </div>
            </Fade>
          </div>
        }

      </div>
    )
  }
}

export default Home;
