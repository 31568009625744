import React, { Component } from 'react';
import { AiFillRightCircle } from 'react-icons/ai'
import Fade from 'react-reveal/Fade';

class Areas extends Component {
  render () {
    return (
      <div className="content_container">
        <div className="areas_container">
          {this.props.areas.length > 0 && this.props.areas.map((area, index) => (
            <Fade down key={index}>
              <div className="area" key={index}>
                <p><AiFillRightCircle/> {area.area}</p>
              </div>
            </Fade>
          ))}
        </div>
      </div>
    )
  }
}

export default Areas;
