import React, { Component } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import { Markdown } from 'react-showdown';

class Services extends Component {
  render () {
    return (
      <Fade>
        <div className="content_container">
          <Tab.Container id="left-tabs-example" defaultActiveKey="advisory/transactional">
            <div className="services_container">
              <div className="services_navigation">
                <Nav variant="pills" className="flex-column">
                  {this.props.services.length > 0 && this.props.services.map((service, index) => (
                    <Nav.Item key={index}>
                      <Nav.Link eventKey={service.title.replace(/\s/g, '')} className="capitalize">{service.title}</Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
              </div>
              <div className="services_description">
                <Tab.Content>
                  {this.props.services.length > 0 && this.props.services.map((service, index) => (
                    <Tab.Pane key={index} eventKey={service.title.replace(/\s/g, '')}>
                      <Fade delay={100}>
                        <p className="subheading capitalize" style={{textAlign: 'left', marginTop: '0px'}}>{service.title}</p>
                        <div className="service_border"></div>
                        <Markdown markup={service.description} className="service_desc"/>
                      </Fade>
                    </Tab.Pane>
                  ))}
                </Tab.Content>
              </div>
            </div>
          </Tab.Container>
        </div>
      </Fade>
    )
  }
}

export default Services;
