import React, { Component } from 'react';
import { AiFillRightCircle } from 'react-icons/ai';
import Fade from 'react-reveal/Fade';
import {IndexLinkContainer} from 'react-router-bootstrap';

class Areas extends Component {
  render () {
    return (
      <div className="content_container">
        <div className="areas_container">
          {this.props.industries.length > 0 && this.props.industries.map((industry, index) => (
            <Fade down key={index}>
              <div className="area industry" key={index}>
              {/*  <IndexLinkContainer to={`/industries/${industry._id}`}> */}
                  <p><AiFillRightCircle/> {industry.industry}</p>
              {/*  </IndexLinkContainer>*/}
              </div>
            </Fade>
          ))}
        </div>
      </div>
    )
  }
}

export default Areas;
