import React, { Component } from 'react';
import Fade from 'react-reveal/Fade';

class Associates extends Component {
  render () {
    return (
      <Fade>
        <div className="content_container">
          <div className="associates_container">
            {this.props.associates.length > 0 && this.props.associates.map((associate, index) => (
              <div className="associate" key={index}>
                <div className="associate_absolute_border"></div>
                <div className="associate_image">
                  <img className="avatar" src={associate.image_url} alt={associate.name}/>
                </div>
                <div className="associate_info">
                  <p className="associate_name">{associate.name} {associate.surname}</p>
                  <p className="associate_desig">{associate.designation}</p>
                  <p className="associate_desc">{associate.profile_description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Fade>
    )
  }
}

export default Associates;
