import React from "react";
import Slider from "react-slick";
import {IndexLinkContainer} from 'react-router-bootstrap';

class SimpleSlider extends React.Component {
  open = (link) => {
    window.open(link, "articlepost");
  }

  render() {
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      fade: true,
    };
    return (
      <Slider style={{width: '100%', marginBottom: '40px'}} {...settings}>
        <IndexLinkContainer to={`/announcements/${this.props.latestAnn._id}`}>
          <div className="news_highlight">
              <img alt="Latest Announcement Banner" className="news_highlight_img" src={this.props.latestAnn.image_url}/>
              <h2 className="subheading">{this.props.latestAnn.title}</h2>
              <p className="news_highlight_desc">{this.props.latestAnn.brief_desc}</p>

          </div>
        </IndexLinkContainer>
        <IndexLinkContainer to={`/news/${this.props.latestArticle._id}`}>
          <div className="news_highlight">
            <img alt="Latest Article Banner" className="news_highlight_img" src={this.props.latestArticle.image_url}/>
            <h2 className="subheading">{this.props.latestArticle.title}</h2>
            <p className="news_highlight_desc">{this.props.latestArticle.brief_desc}</p>
          </div>
        </IndexLinkContainer>
        <div className="news_highlight" onClick={() => this.open(this.props.latestPub.link)}>
          <img alt="Latest Publication Banner" className="news_highlight_img" src={this.props.latestPub.image_url}/>
          <h2 className="subheading">{this.props.latestPub.title}</h2>
          <p className="news_highlight_desc">{this.props.latestPub.description}</p>
        </div>
      </Slider>
    );
  }
}

export default SimpleSlider;
