import React, { Component } from 'react';
import { TiSocialTwitter } from "react-icons/ti";
import { TiSocialLinkedin } from "react-icons/ti";
import { TiSocialFacebook } from "react-icons/ti";
import { TiMail } from "react-icons/ti";
import { MdPhone } from "react-icons/md";

class Footer extends Component {
  twitter = () => {
    window.open("https://twitter.com/axislawpk", "TwitterProfile");
  }

  facebook = () => {
    window.open("https://www.facebook.com/axislawchambers/?_rdc=1&_rdr", "FacebookProfile");
  }

  linkedIn = () => {
    window.open("https://www.linkedin.com/company/hassan-&-rahim/", "LinkedInProfile");
  }

  render () {
    var year = new Date().getFullYear();
    return (
      <div>
        <footer className="footer">
          <div className="content_container" style={{marginTop: '30px', marginBottom: '30px'}}>
            <div className="footer_flexed_section">
              <div className="footer_flexed_item">
                <p className="foot_sub_heading">Email</p>
                <div className="flex_mapped">
                  {this.props.emails.length > 0 && this.props.emails.map((email, index) => (
                    <div className="footer_email" key={index}>
                      <TiMail className="footer_text footer_icon" size={20} style={{marginLeft: '-1px'}}/>
                      <span className="footer_text">{email.email}</span>
                    </div>
                  ))}
                </div>
              </div>
              <div className="footer_flexed_item">
                <p className="foot_sub_heading">Contact</p>
                <div className="flex_mapped">
                  {this.props.phones.length > 0 && this.props.phones.map((phone, index) => (
                    <div className="footer_phone" key={index}>
                      <MdPhone className="footer_text footer_icon" size={20} style={{marginLeft: '-1px'}}/>
                      <span className="footer_text">{phone.phone_number}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <p className="foot_sub_heading"></p>
            <div className="footer_social">
              <TiSocialLinkedin onClick={() => this.linkedIn()} style={{marginLeft: '-3px'}} className="footer_social" size={26}/>
              <TiSocialTwitter onClick={() => this.twitter()} className="footer_social" size={26}/>
              <TiSocialFacebook onClick={() => this.facebook()} className="footer_social" size={26}/>
            </div>
          </div>
          <div className="footer_logo_flex">
            <div className="footer_flex_content content_container" style={{marginTop: '0px'}}>
              <p className="copyright_message" style={{margin: '5px 0px'}}>Copyright © 2014 - {year} {this.props.name}</p>
            </div>
          </div>
          <div className="footer_absolute_top_border"></div>
          <div className="footer_absolute_bottom_border"></div>
          <div className="footer_absolute_bottom_border_next"></div>
        </footer>

      </div>
    )
  }
}

export default Footer;
