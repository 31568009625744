import React, { Component } from 'react';
import axios from 'axios';
import RingLoader from 'react-spinners/RingLoader';
import Fade from 'react-reveal/Fade';
import NavBar from '../components/navbar';
import SectionTitle from '../components/sectiontitle';
import ContactForm from '../components/contactform';
import Footer from '../components/footer';

class Contact extends Component {
  state = {
    loading: true,
  }

  componentDidMount () {
    const companyPromise = axios.get('https://cms-axis.herokuapp.com/companyinfos');
    const contactphotoPromise = axios.get('https://cms-axis.herokuapp.com/contactphotos');

    const parent = this;

    Promise.all([companyPromise, contactphotoPromise]).then(function(values) {
      console.log(values);
      parent.setState({
        companyInfo: values[0].data[values[0].data.length-1],
        contactphotos: values[1].data,
        loading: false
      })
    });
  }

  render () {
    return (
      <div>
        {this.state.loading === true ?
          <div className="loading">
            <RingLoader
              sizeUnit={"px"}
              size={50}
              color={'#222F55'}
              loading={this.state.loading}
            />
          </div>
          :
          <div>
            <Fade>
              <div>
                <NavBar color='#FFFFFF' textcolor='#777' navitems={['ABOUT US', 'SERVICES', 'PROJECTS', 'OUR TEAM']}/>
                <SectionTitle title="Contact Us"/>
                <ContactForm locations={this.state.companyInfo.addresses} hours={this.state.companyInfo.office_hours} photos={this.state.contactphotos}/>
                <Footer name={this.state.companyInfo.Name} phones={this.state.companyInfo.phones} emails={this.state.companyInfo.emails}/>
              </div>
            </Fade>
          </div>
        }
      </div>
    )
  }
}

export default Contact;
