import React, { Component } from 'react';
import axios from 'axios';
import RingLoader from 'react-spinners/RingLoader';
import Fade from 'react-reveal/Fade';
import NavBar from '../components/navbar';
import SectionTitle from '../components/sectiontitle';
import { Markdown } from 'react-showdown';
import Footer from '../components/footer';
import {IndexLinkContainer} from 'react-router-bootstrap';

class AnnDetail extends Component {
  state = {
    loading: true,
  }

  componentDidMount () {
    var string = this.props.location.pathname;
    var split = string.split("/");
    var id= split[split.length - 1];
    const articlelink = "https://cms-axis.herokuapp.com/announcements/" + id;

    const companyPromise = axios.get('https://cms-axis.herokuapp.com/companyinfos');
    const articlePromise = axios.get(articlelink);
    const parent = this;

    Promise.all([companyPromise, articlePromise]).then(function(values) {
      if (values[1].data.new_partner_first_name !== undefined && values[1].data.new_partner_last_name !== undefined) {
        const partnerLink = 'https://cms-axis.herokuapp.com/partners?name_eq=' + values[1].data.new_partner_first_name + '&surname_eq=' + values[1].data.new_partner_last_name;
        axios.get(partnerLink).then(res => parent.setState({
          partner_id: res.data[0]._id
        }));
      }
      parent.setState({
        companyInfo: values[0].data[values[0].data.length-1],
        annDetail: values[1].data,
        loading: false
      });
      document.querySelectorAll(".news_nav")[0].classList.add("active");
    });
  }

  open = (link) => {
    window.open(link, "articlepost");
  }


  render () {
    return (
      <div className="">
        {this.state.loading === true ?
          <div className="loading">
            <RingLoader
              sizeUnit={"px"}
              size={50}
              color={'#222F55'}
              loading={this.state.loading}
            />
          </div>
          :
          <div>
            <Fade>
              <div>
                <NavBar color='#FFFFFF'/>
                <SectionTitle title={this.state.annDetail.title}/>
                <div className="content_container announcement_detail">
                  <div className="markdown">
                    <Markdown className="" markup= { this.state.annDetail.detailed_description }/>
                  </div>
                </div>
                <div className="content_container" style={{display: 'flex', justifyContent: 'center'}}>
                  {this.state.partner_id !== undefined ?
                    <IndexLinkContainer to={`/team/${this.state.partner_id}`}>
                      <div className="button home_services_button">
                        <p className="button_text">Partner Profile</p>
                      </div>
                    </IndexLinkContainer>
                  :
                    [
                      (this.state.annDetail.title.includes("Recruitment") === true
                        ? <IndexLinkContainer to="/careers">
                            <div className="button home_services_button">
                              <p className="button_text">Axis Law Careers</p>
                            </div>
                          </IndexLinkContainer>
                        :
                        <IndexLinkContainer to="/capabilities">
                          <div className="button home_services_button">
                            <p className="button_text">Axis Law Capabilities</p>
                          </div>
                        </IndexLinkContainer>
                      )
                    ]
                  }
                </div>
                <Footer name={this.state.companyInfo.Name} locations={this.state.companyInfo.addresses} phones={this.state.companyInfo.phones} emails={this.state.companyInfo.emails} hours={this.state.companyInfo.office_hours} />
              </div>
            </Fade>
          </div>
        }
      </div>
    )
  }
}

export default AnnDetail;
