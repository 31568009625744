import React, { Component } from 'react';
import axios from 'axios';
import RingLoader from 'react-spinners/RingLoader';
import Fade from 'react-reveal/Fade';
import NavBar from '../components/navbar';
import SectionTitle from '../components/sectiontitle';
import CareerPhotos from '../components/careerphotos';
import CareerOpps from '../components/careeropps';
import JobListing from '../components/job';
import Footer from '../components/footer';

class Careers extends Component {
  state = {
    loading: true,
  }

  componentDidMount () {
    const companyPromise = axios.get('https://cms-axis.herokuapp.com/companyinfos');
    const careerPromise = axios.get('https://cms-axis.herokuapp.com/careers');
    const careerListingPromise = axios.get('https://cms-axis.herokuapp.com/careerlistings');
    const parent = this;

    Promise.all([companyPromise, careerPromise, careerListingPromise]).then(function(values) {
      parent.setState({
        companyInfo: values[0].data[values[0].data.length-1],
        careers: values[1].data[values[1].data.length-1],
        careerlistings: values[2].data,
        loading: false
      })
    });
  }

  render () {
    return (
      <div>
        {this.state.loading === true ?
          <div className="loading">
            <RingLoader
              sizeUnit={"px"}
              size={50}
              color={'#222F55'}
              loading={this.state.loading}
            />
          </div>
          :
          <div>
            <Fade>
              <div>
                <NavBar color='#FFFFFF' textcolor='#777' navitems={['ABOUT US', 'SERVICES', 'PROJECTS', 'OUR TEAM']}/>
                <SectionTitle title="Careers"/>
                <CareerPhotos title={this.state.careers.title} description={this.state.careers.description} photos={this.state.careers.careerphotos}/>
                <CareerOpps opp_first_title={this.state.careers.opp_first_title} opp_first_details={this.state.careers.opp_first_detail} opp_sec_title={this.state.careers.opp_sec_title} opp_sec_details={this.state.careers.opp_sec_detail}/>
                <div className="content_container" style={{display: 'none'}}>
                  <p className="job_listings_title">Job Listings</p>
                {this.state.careerlistings.length === 0 ?
                    <p className="no_jobs">There are no job openings at Axis Law Chambers at the moment</p>
                  :
                  <div className="jobs_container">
                  {this.state.careerlistings.map((job, index) => (
                      <JobListing key={index} title={job.job_title} location={job.job_location} experience={job.job_experience} timing={job.job_time} url={job.application_url} desc={job.job_description} roles={job.job_roles} id={job._id} deadline={job.deadline}/>
                  ))}
                  </div>
                }
                </div>
                <Footer name={this.state.companyInfo.Name} locations={this.state.companyInfo.addresses} phones={this.state.companyInfo.phones} emails={this.state.companyInfo.emails} hours={this.state.companyInfo.office_hours} />
              </div>
            </Fade>
          </div>
        }
      </div>
    )
  }
}

export default Careers;
