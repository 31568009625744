import React, { Component } from 'react';
import axios from 'axios';
import RingLoader from 'react-spinners/RingLoader';
import Fade from 'react-reveal/Fade';
import NavBar from '../components/navbar';
import SectionTitle from '../components/sectiontitle';
import Services from '../components/services';
import Areas from '../components/areas';
import Industries from '../components/industries';
import Footer from '../components/footer';

class Capabilities extends Component {
  state = {
    loading: true,
    current_section: "services_display"
  }

  componentDidMount () {
    const companyPromise = axios.get('https://cms-axis.herokuapp.com/companyinfos');
    const servicePromise = axios.get('https://cms-axis.herokuapp.com/services');
    const industryPromise = axios.get('https://cms-axis.herokuapp.com/industries');
    const areasPromise = axios.get('https://cms-axis.herokuapp.com/practiceareas');

    const parent = this;

    Promise.all([companyPromise, servicePromise, industryPromise, areasPromise]).then(function(values) {
      console.log(values);
      parent.setState({
        companyInfo: values[0].data[values[0].data.length-1],
        services: values[1].data,
        industries: values[2].data,
        areas: values[3].data,
        loading: false
      })
    });
  }

  setSection = (id) => {
    var button = document.getElementById(id);
    var elems = document.querySelectorAll(".custom_tabs div");

    [].forEach.call(elems, function(el) {
        el.classList.remove("active");
    });

    this.setState({current_section: id});
    button.classList.add("active");
  }

  render () {
    return (
      <div>
        {this.state.loading === true ?
          <div className="loading">
            <RingLoader
              sizeUnit={"px"}
              size={50}
              color={'#222F55'}
              loading={this.state.loading}
            />
          </div>
          :
          <div>
            <Fade>
              <div>
                <NavBar color='#FFFFFF' textcolor='#777'/>
                <SectionTitle title="Capabilities"/>
                <div className="content_container" style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                  <div className="custom_tabs">
                    <div className="active" id="services_display" onClick={() => this.setSection("services_display")}>Services</div>
                    <div id="areas_display" onClick={() => this.setSection("areas_display")}>Practice Areas</div>
                    <div id="industries_display" onClick={() => this.setSection("industries_display")}>Industries</div>
                  </div>
                </div>
                {this.state.current_section === "services_display" ? <Services services={this.state.services}/> : <div></div>}
                {this.state.current_section === "areas_display" ? <Areas areas={this.state.areas}/> : <div></div>}
                {this.state.current_section === "industries_display" ? <Industries industries={this.state.industries}/> : <div></div>}
                <Footer name={this.state.companyInfo.Name} locations={this.state.companyInfo.addresses} phones={this.state.companyInfo.phones} emails={this.state.companyInfo.emails} hours={this.state.companyInfo.office_hours} />
              </div>
            </Fade>
          </div>
        }
      </div>
    )
  }
}

export default Capabilities;
