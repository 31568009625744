import React, { Component } from 'react';
import {Form} from 'react-bootstrap';
import { IoLogoFacebook } from 'react-icons/io';
import { IoLogoTwitter } from 'react-icons/io';
import { IoLogoLinkedin } from 'react-icons/io';
import { MdLocationCity } from 'react-icons/md';
import { MdPhone } from 'react-icons/md';
import { MdEmail } from 'react-icons/md';
import { IoIosPeople } from 'react-icons/io';
import * as emailjs from 'emailjs-com';
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';
import {Carousel} from 'react-bootstrap';

class ContactForm extends Component {

  twitter = () => {
    window.open("https://twitter.com/axislawpk", "TwitterProfile");
  }

  facebook = () => {
    window.open("https://www.facebook.com/axislawchambers/?_rdc=1&_rdr", "FacebookProfile");
  }

  linkedIn = () => {
    window.open("https://www.linkedin.com/company/hassan-&-rahim/", "LinkedInProfile");
  }

  state = {
    name: '',
    email: '',
    subject: '',
    message: '',
    thank: false
  }

  handleSubmit(e) {
    e.preventDefault()
    const { name, email, subject, message } = this.state;
    let templateParams = {
      from_name: name,
      from_email: email,
      to_name: 'info@axislaw.pk',
      subject: subject,
      message_html: message,
     }

     const parent = this;

     emailjs.send(
      'gmail',
      'template_256oq4wd',
       templateParams,
      'user_LbowNJZLKcWDpORs43dyD'
     ).then(function(response) {
       parent.setState({
         thank: true
       })
       console.log('SUCCESS!', response.status, response.text);
     }, function(error) {
       console.log('FAILED...', error);
     });

     this.resetForm();
   }

   resetForm() {
    this.setState({
      name: '',
      email: '',
      subject: '',
      message: '',
    })
  }

  handleChange = (param, e) => {
    this.setState({ [param]: e.target.value })
  }

  render () {
    return (
      <div className="content_container">
        <div className="contact_flex">
          <div className="contact_form">
            <Slide down delay={250}>
              <p className="heading" style={{textAlign: 'left', marginBottom: '40px'}}>Send us a message</p>
            </Slide>
            <Fade delay={450}>
              <Form>
                <div className="flexed_form">
                  <div className="flexed_form_group">
                    <Form.Group required>
                      <Form.Label>Name <span style={{color: 'red'}}>*</span></Form.Label>
                      <Form.Control required type="text" value={this.state.name} onChange={this.handleChange.bind(this, 'name')} placeholder="Enter your name" />
                    </Form.Group>
                  </div>
                  <div className="flexed_form_group">
                    <Form.Group>
                      <Form.Label>Email address <span style={{color: 'red'}}>*</span></Form.Label>
                      <Form.Control required type="email" value={this.state.email} onChange={this.handleChange.bind(this, 'email')} placeholder="Enter email" />
                    </Form.Group>
                  </div>
                </div>
                <Form.Group>
                  <Form.Label>Subject</Form.Label>
                  <Form.Control type="text" value={this.state.subject} onChange={this.handleChange.bind(this, 'subject')} placeholder="Enter the subject of your message" />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Message</Form.Label>
                  <Form.Control as="textarea" rows="5" type="text" value={this.state.message} onChange={this.handleChange.bind(this, 'message')} placeholder="Enter your message" />
                </Form.Group>
              </Form>
            </Fade>
            {this.state.thank === true ?
              <Fade>
                <p className="contact_thankyou">Thank you for contacting us. You'll hear from us shortly</p>
              </Fade>
              :
              <div style={{height: '24px', width: '24px'}}>
              </div>
            }
            <div className="button contact_button" onClick={(e) => this.handleSubmit(e)}>
              <p className="button_text">Submit</p>
            </div>
          </div>
          <div className="google_map">
            <div className="contact_image_container">
              <Fade delay={750}>
                <div className="contact_slider">
                  <Carousel fade={true}>
                    {this.props.photos.length > 0 && this.props.photos.map((photo, index) => (
                      <Carousel.Item className="slide_item" key={index}>
                        <img className="axis_office_image" src={photo.image_url} alt='Axis Office Space'/>
                      </Carousel.Item>
                    ))}
                  </Carousel>
                </div>
              </Fade>
            </div>
            <div className="contact_email">
              <div className="contact_email_section">
                <MdEmail className="contact_email_icon"/>
                <p className="contact_email_text">info@axislaw.pk</p>
              </div>
              <div className="contact_email_section">
                <IoIosPeople className="contact_email_icon"/>
                <p className="contact_email_text">careers@axislaw.pk</p>
              </div>
            </div>
            <div className="contact_social">
              <div className="contact_social_icons">
                <IoLogoFacebook onClick={() => this.facebook()} className="contact_social_icon" size={28}/>
                <IoLogoTwitter onClick={() => this.twitter()} className="contact_social_icon" size={28}/>
                <IoLogoLinkedin onClick={() => this.linkedIn()} className="contact_social_icon" size={28}/>
              </div>
            </div>
          </div>
        </div>
        <div className="contact_locations">
          {this.props.locations.length > 0 && this.props.locations.map((location, index) => (
            <div className="contact_location" key={index}>
              <Fade down delay={index*300}>
                <p className="contact_location_heading">{location.location} Office</p>
              </Fade>
              <div className="contact_location_separator"></div>
              <Fade up delay={index*500}>
                <div className="contact_location_info_container">
                  <div className="contact_location_icon">
                    <MdLocationCity/>
                  </div>
                  <div className="contact_location_info">
                    <p className="contact_location_address">{location.address}</p>
                  </div>
                </div>
              </Fade>
              <Fade up delay={index*700}>
                <div className="contact_location_info_container">
                  <div className="contact_location_icon">
                    <MdPhone/>
                  </div>
                  <div className="contact_location_info">
                    <p className="contact_location_address">{location.phone}</p>
                  </div>
                </div>
              </Fade>
            </div>
          ))}
        </div>
      </div>
    )
  }
}

export default ContactForm;
