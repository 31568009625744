import React, { Component } from 'react';
import axios from 'axios';
import RingLoader from 'react-spinners/RingLoader';
import Fade from 'react-reveal/Fade';
import NavBar from '../components/navbar';
import SectionTitle from '../components/sectiontitle';
import Footer from '../components/footer';

class JobDetail extends Component {
  state = {
    loading: true,
  }

  componentDidMount () {
    var string = this.props.location.pathname;
    var split = string.split("/");
    var id= split[split.length - 1];
    const joblink = "https://cms-axis.herokuapp.com/careerlistings/" + id;

    const companyPromise = axios.get('https://cms-axis.herokuapp.com/companyinfos');
    const jobPromise = axios.get(joblink);
    const parent = this;

    Promise.all([companyPromise, jobPromise]).then(function(values) {
      parent.setState({
        companyInfo: values[0].data[values[0].data.length-1],
        jobdetail: values[1].data,
        loading: false
      });

      document.querySelectorAll(".careers_nav")[0].classList.add("active");
    });
  }

  apply = (link) => {
    window.open(link, "jobApplication");
  }


  render () {
    return (
      <div className="">
        {this.state.loading === true ?
          <div className="loading">
            <RingLoader
              sizeUnit={"px"}
              size={50}
              color={'#222F55'}
              loading={this.state.loading}
            />
          </div>
          :
          <div>
            <Fade>
              <div>
                <NavBar color='#FFFFFF' textcolor='#777' navitems={['ABOUT US', 'SERVICES', 'PROJECTS', 'OUR TEAM']}/>
                <SectionTitle title={this.state.jobdetail.job_title}/>
                <div className="content_container">
                  <div className="job_details_flex">
                    <div className="job_details">
                      <p className="job_detail_title">Job Description</p>
                      <p className="job_detail_text">{this.state.jobdetail.job_description}</p>
                      <p className="job_detail_title">Job Roles</p>
                      <p className="job_detail_text">{this.state.jobdetail.job_roles}</p>
                      <p className="job_detail_title">Experience</p>
                      <p className="job_detail_text">{this.state.jobdetail.job_experience}</p>
                      <p className="job_detail_title">Location</p>
                      <p className="job_detail_text">{this.state.jobdetail.job_location}</p>
                      <p className="job_detail_title">Timing</p>
                      <p className="job_detail_text">{this.state.jobdetail.job_time}</p>
                    </div>
                    <div className="job_apply_pane">
                      <p className="deadline">The deadline to apply is {this.state.jobdetail.deadline}</p>
                      <div className="button job_button" onClick={() => this.apply(this.state.jobdetail.application_url)}>
                        <p className="button_text">Apply</p>
                      </div>
                    </div>
                  </div>
                </div>
                <Footer name={this.state.companyInfo.Name} locations={this.state.companyInfo.addresses} phones={this.state.companyInfo.phones} emails={this.state.companyInfo.emails} hours={this.state.companyInfo.office_hours} />
              </div>
            </Fade>
          </div>
        }
      </div>
    )
  }
}

export default JobDetail;
