import React, { Component } from 'react';
import {IndexLinkContainer} from 'react-router-bootstrap';

class Job extends Component {
  render () {
    return (
      <div className="job">
        <p className="job_title">Title: {this.props.title}</p>
        <p className="job_title">Location: {this.props.location}</p>
        <p className="job_title">Experience: {this.props.experience}</p>
        <p className="job_title">Deadline: {this.props.deadline}</p>

        <IndexLinkContainer to={`/careers/${this.props.id}`}>
          <div className="button job_button">
            <p className="button_text">Details</p>
          </div>
        </IndexLinkContainer>
      </div>
    )
  }
}

export default Job;
