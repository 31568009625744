import React, { Component } from 'react';
import {Carousel} from 'react-bootstrap'

class Slider extends Component {
  render () {
    return (
      <div className="slide_contain">
        <Carousel fade={true} className="slide" indicators={false}>
          {this.props.slides.length > 0 && this.props.slides.map((slide, index) => (
            <Carousel.Item className="slide_item" key={index}>
              <img className="slide_image" src={slide.image_url} alt={slide.client_compliment}/>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    )
  }
}

export default Slider;
