import React, { Component } from 'react';
import {IndexLinkContainer} from 'react-router-bootstrap';
import Fade from 'react-reveal/Fade';
import { MdBubbleChart } from "react-icons/md";


class HomeServices extends Component {

  state ={}

  setSection = (id) => {
    var button = document.getElementById(id);
    var elems = document.querySelectorAll(".custom_tabs div");

    [].forEach.call(elems, function(el) {
        el.classList.remove("active");
    });

    var concat_id = id + "_desc";

    var descs = document.getElementsByClassName("home_service");

    [].forEach.call(descs, function(el) {
      el.style.display = "none";
    });

    document.getElementById(concat_id).style.display = "block";

    this.setState({current_section: id});
    button.classList.add("active");
  }

  render () {
    return (
        <div className="content_container">
          <div className="home_services_container">
            <div className="heading_icon_container">
              <MdBubbleChart className="heading_icon"/>
              <h2 className="heading">Our Capabilities</h2>
            </div>
            <div className="home_services">
              <div className="custom_tabs">
                {this.props.services.length > 0 && this.props.services.map((service, index) => (
                  index === 0 ? <div key={index} className="active" id={service.title.replace(/\s/g, '')} onClick={(e) => this.setSection(service.title.replace(/\s/g, ''))}>{service.title}</div> : <div className="" key={index} id={service.title.replace(/\s/g, '')} onClick={(e) => this.setSection(service.title.replace(/\s/g, ''))}>{service.title}</div>
                ))}
              </div>
              <div className="service_descriptions">
              {this.props.services.length > 0 && this.props.services.map((service, index) => (
                <div key={index} className="home_service" id={service.title.replace(/\s/g, '') + "_desc"}>
                  <Fade>
                    <p className="home_service_description">{service.description}</p>
                  </Fade>
                </div>
              ))}
              </div>
              <IndexLinkContainer to="/capabilities">
                <div className="button home_services_button">
                  <p className="button_text">View Capabilities in Detail</p>
                </div>
              </IndexLinkContainer>
            </div>
            <div className="home_services_banner_container">
              <img className="home_services_banner" src={this.props.image} alt="Services offered by Axis Law"/>
            </div>
          </div>
        </div>
    )
  }
}

export default HomeServices;
