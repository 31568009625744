import React from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';
import Home from './views/home';
import About from './views/about';
import Capabilities from './views/capabilities';
import Contact from './views/contact';
import Team from './views/people';
import News from './views/news';
import Careers from './views/career';
import JobDetail from './views/jobdetails';
import ArticleDetail from './views/articledetails';
import AnnDetail from './views/anndetails';
import PartnerDetail from './views/partnerdetails';
import IndustryDetail from './views/industrydetails';
import './App.css';

function App() {
  return (
    <div className="App">
      <Switch>
        <Route exact path ="/" component={Home}/>
        <Route exact path ="/about" component={About}/>
        <Route exact path ="/capabilities" component={Capabilities}/>
        <Route exact path ="/industries/:id" component={IndustryDetail}/>
        <Route exact path ="/team" component={Team}/>
        <Route path="/team/:id" component={PartnerDetail} />
        <Route exact path ="/news" component={News}/>
        <Route path="/news/:id" component={ArticleDetail} />
        <Route path="/announcements/:id" component={AnnDetail} />
        <Route exact path ="/careers" component={Careers}/>
        <Route path="/careers/:id" component={JobDetail} />
        <Route exact path ="/contact" component={Contact}/>
        <Redirect to="/" />
      </Switch>
    </div>
  );
}

export default App;
