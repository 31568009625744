import React, { Component } from 'react';
import { TiLocationArrow } from "react-icons/ti";
import Fade from 'react-reveal/Fade';

class HomeLocations extends Component {
  render () {
    return (
        <div className="content_container">
          <div className="home_locations_container">
            {this.props.locations.length > 0 && this.props.locations.map((location, index) => (
              <Fade down delay={index*200} key={index}>
                <div className="home_location">
                  <TiLocationArrow size={18} style={{marginLeft: '-3px'}}/>
                  <div className="home_location_loc">
                    <span className="bold" style={{marginRight: '4px'}}>{location.location}:</span><span>{location.address}</span>
                  </div>
                </div>
              </Fade>
            ))}
          </div>
        </div>
    )
  }
}

export default HomeLocations;
