import React, { Component } from 'react';
import { Markdown } from 'react-showdown';
import internship from '../resources/girl-work-on-laptop-1792788-1519329.png';
import job from '../resources/teamwork-and-brainstorming-1460453-1234504.jpg';
import Fade from 'react-reveal/Fade';

class CareerOpps extends Component {
  setSection = (id) => {
    var button = document.getElementById(id);
    var elems = document.querySelectorAll(".custom_tabs div");

    [].forEach.call(elems, function(el) {
        el.classList.remove("active");
    });

    var concat_id = id + "_desc";

    var descs = document.getElementsByClassName("career_opp");

    [].forEach.call(descs, function(el) {
      el.style.display = "none";
    });

    document.getElementById(concat_id).style.display = "flex";

    this.setState({current_section: id});
    button.classList.add("active");
  }


  render () {
    return (
      <div className="content_container">
        <div className="career_opp_flex">
          <div className="custom_tabs">
            <div key={this.props.opp_first_title} className="active" id={this.props.opp_first_title.replace(/\s/g, '')} onClick={(e) => this.setSection(this.props.opp_first_title.replace(/\s/g, ''))}>Annual Recruitment</div>
            <div key={this.props.opp_sec_title} id={this.props.opp_sec_title.replace(/\s/g, '')} onClick={(e) => this.setSection(this.props.opp_sec_title.replace(/\s/g, ''))}>Summer Internships</div>
          </div>
          <div className="career_opp" id="AnnualRecruitmentCycle_desc">
            <Fade left>
              <div className="career_opp_image">
                <img alt="Annual Recruitment Interview" src={job} style={{width: '100%'}}></img>
              </div>
            </Fade>
            <Fade delay={300}>
              <div  className="career_opp_container">
                <p className="subheading" style={{textAlign: 'left'}}>
                  {this.props.opp_first_title}
                </p>
                <Markdown className="career_opp_desc" markup={ this.props.opp_first_details } />
              </div>
            </Fade>
          </div>
          <div className="career_opp" id="AxisSummerInternshipProgramme_desc">
            <Fade left>
              <div className="career_opp_image">
                <img alt="Intern" src={internship} style={{width: '100%'}}></img>
              </div>
            </Fade>
            <Fade delay={300}>
              <div className="career_opp_container">
                <p className="subheading" style={{textAlign: 'left'}}>
                  {this.props.opp_sec_title}
                </p>
                <Markdown className="career_opp_desc" markup={ this.props.opp_sec_details } />
              </div>
            </Fade>
          </div>
        </div>
      </div>
    )
  }
}

export default CareerOpps;
