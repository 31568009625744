import React, { Component } from 'react';
import {Navbar, Nav} from 'react-bootstrap';
import logo from '../resources/logo.png';
import {IndexLinkContainer} from 'react-router-bootstrap'

class NavBar extends Component {
  render (){
    return (
        <Navbar collapseOnSelect expand="lg" className="navbar" variant="dark">
          <div className="background_nav_logo_container">
            <div className="background_nav_logo">
            </div>
          </div>
          <Navbar.Brand className="brand" style={{color: this.props.textcolor, display: 'flex', alignItems: 'flex-end'}}>
            <IndexLinkContainer style={{height: '70px'}} className="pad_nav_logo" to={'/'}>
              <img style={{width: '100%'}} href='/' src={logo} className="App-logo" alt="Axis logo" />
            </IndexLinkContainer>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" className="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
            <Nav>
              <IndexLinkContainer to="/about">
                <Nav.Link className="padded_link">The Firm</Nav.Link>
              </IndexLinkContainer>
              <IndexLinkContainer to="/capabilities">
                <Nav.Link className="padded_link cap_nav">Capabilities</Nav.Link>
              </IndexLinkContainer>
              <IndexLinkContainer to="/team">
                <Nav.Link className="padded_link lawyer_nav">Lawyers</Nav.Link>
              </IndexLinkContainer>
              <IndexLinkContainer to="/careers">
                <Nav.Link className="padded_link careers_nav">Careers</Nav.Link>
              </IndexLinkContainer>
              <IndexLinkContainer to="/news">
                <Nav.Link className="padded_link news_nav">News & Updates</Nav.Link>
              </IndexLinkContainer>
              <IndexLinkContainer to="/contact">
                <Nav.Link className="padded_link">Contact Us</Nav.Link>
              </IndexLinkContainer>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
    )
  }
}

export default NavBar;
