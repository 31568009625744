import React, { Component } from 'react';
import { Markdown } from 'react-showdown';

class TextContainer extends Component {
  render () {
    return (
      <div>
        {this.props.image === undefined ?
          <div className="text_section">
            <div className="content_container">
              <h2 className="heading">{this.props.title}</h2>
              {this.props.text !== undefined ? <p>{this.props.text}</p>
                :
                <div className="centered_paragraphs">
                  <Markdown markup={ this.props.markdown } />
                </div>
              }
                {this.props.paragraphs !== undefined &&  this.props.paragraphs.length > 0 && this.props.paragraphs.map((paragraph, index) => (
                  <p key={index}>{paragraph.text}</p>
                ))}
              <div>
              {this.props.links !== undefined ?
                <div className="international_links">
                  {this.props.links.length > 0 && this.props.links.map((link, index) => (
                    <div className="international_link" key={index}>
                      <div className="link_name_logo_flex">
                        <p className="international_link_name" key={index}>{link.link_name}</p>
                        <img className="international_link_logo" src={link.link_logo_url} alt={link.link_name}/>
                      </div>
                      <p className="international_link_desc" key={index}>{link.link_description}</p>
                    </div>
                  ))}
                </div>
                :
                <div>
                </div>
                }
                </div>
            </div>
          </div>
          :
          <div className="grey_background pro_bono_container">
            <div className="content_container">
              <div className="pro_bono_flex">
                <div className="pro_bono_image">
                  <img className="pro_bono_banner" src={this.props.image} alt="Pro Bono Services"/>
                </div>
                <div className="pro_bono_text">
                  <h2 className="heading" style={{textAlign: 'left', marginBottom: '10px'}}>{this.props.title}</h2>
                  {this.props.text !== undefined ? <p>{this.props.text}</p>
                    :
                    <Markdown  markup={ this.props.markdown } />
                  }
                  {this.props.paragraphs !== undefined &&  this.props.paragraphs.length > 0 && this.props.paragraphs.map((paragraph, index) => (
                    <p key={index}>{paragraph.text}</p>
                  ))}
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    )
  }
}

export default TextContainer;
