import React, { Component } from 'react';

class SectionTitle extends Component {
  render () {
    return (
      <div className="section_title_container">
        <div className="section_title">
          <h2 style={{color: '#FFEB3B'}}>{this.props.title}</h2>
        </div>
        <div className="section_title_border"></div>
      </div>
    )
  }
}

export default SectionTitle;
