import React, { Component } from 'react';
import axios from 'axios';
import RingLoader from 'react-spinners/RingLoader';
import Fade from 'react-reveal/Fade';
import NavBar from '../components/navbar';
import SectionTitle from '../components/sectiontitle';
import { Markdown } from 'react-showdown';
import Footer from '../components/footer';

class PartnerDetail extends Component {
  state = {
    loading: true,
  }

  componentDidMount () {
    var string = this.props.location.pathname;
    var split = string.split("/");
    var id= split[split.length - 1];
    const partnerlink = "https://cms-axis.herokuapp.com/partners/" + id;

    const companyPromise = axios.get('https://cms-axis.herokuapp.com/companyinfos');
    const partnerPromise = axios.get(partnerlink);
    const parent = this;

    Promise.all([companyPromise, partnerPromise]).then(function(values) {
      parent.setState({
        companyInfo: values[0].data[values[0].data.length-1],
        partnerProfile: values[1].data,
        partnerName: values[1].data.name + ' ' + values[1].data.surname,
        loading: false
      });

      document.querySelectorAll(".lawyer_nav")[0].classList.add("active");
    });
  }

  open = (link) => {
    window.open(link, "articlepost");
  }

  mailto = (email) => {
    var emailto = "mailto:" + email;
    window.open(emailto, "OpenMail");
  }

  render () {
    return (
      <div className="">
        {this.state.loading === true ?
          <div className="loading">
            <RingLoader
              sizeUnit={"px"}
              size={50}
              color={'#222F55'}
              loading={this.state.loading}
            />
          </div>
          :
          <div>
            <Fade>
              <div>
                <NavBar color='#FFFFFF' active="lawyers"/>
                <SectionTitle title={this.state.partnerName}/>
                <div className="content_container">
                  <div className="partner_detail_flex">
                    <div className="partner_detail_info">
                      <div className="partner_detail_image">
                        <img className="avatar" src={this.state.partnerProfile.image_url} alt={this.state.partnerProfile.name}/>
                      </div>
                      <p className="subheading" style={{textAlign: 'left'}}>{this.state.partnerProfile.name} {this.state.partnerProfile.surname}</p>
                      <div className="partner_name_separator"></div>
                      <p className="bold partner_detail_title">Bar Memberships</p>
                      <p>{this.state.partnerProfile.bar_memberships}</p>
                      <p className="bold partner_detail_title">Education</p>
                      <Markdown markup={this.state.partnerProfile.education}/>
                      <div className="button job_button" onClick= {() => this.mailto(this.state.partnerProfile.email)}>
                        <p className="button_text">Email</p>
                      </div>
                    </div>
                    <div className="partner_detail_desc">
                      <Markdown markup={this.state.partnerProfile.profile_description}/>
                      {this.state.partnerProfile.chambers_partner_badge !== undefined ?
                        <img className="chambers_partner_badge" src={this.state.partnerProfile.chambers_partner_badge} alt="Chambers and Partners Badge"/>
                        :
                        <div></div>
                      }
                      {this.state.partnerProfile.publication_links !== undefined ?
                        <div className="partner_publications_container">
                          <p className="partner_publications">Publications</p>
                          <Markdown markup={this.state.partnerProfile.publication_links}/>
                        </div>
                        :
                        <div></div>
                      }
                    </div>
                  </div>
                </div>
                <Footer name={this.state.companyInfo.Name} locations={this.state.companyInfo.addresses} phones={this.state.companyInfo.phones} emails={this.state.companyInfo.emails} hours={this.state.companyInfo.office_hours} />
              </div>
            </Fade>
          </div>
        }
      </div>
    )
  }
}

export default PartnerDetail;
