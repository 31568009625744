import React, { Component } from 'react';
import axios from 'axios';
import RingLoader from 'react-spinners/RingLoader';
import Fade from 'react-reveal/Fade';
import NavBar from '../components/navbar';
import SectionTitle from '../components/sectiontitle';
import { Markdown } from 'react-showdown';
import Footer from '../components/footer';

class ArticleDetail extends Component {
  state = {
    loading: true,
  }

  componentDidMount () {
    var string = this.props.location.pathname;
    var split = string.split("/");
    var id= split[split.length - 1];
    const articlelink = "https://cms-axis.herokuapp.com/blogposts/" + id;

    const companyPromise = axios.get('https://cms-axis.herokuapp.com/companyinfos');
    const articlePromise = axios.get(articlelink);
    const parent = this;

    Promise.all([companyPromise, articlePromise]).then(function(values) {
      parent.setState({
        companyInfo: values[0].data[values[0].data.length-1],
        articledetail: values[1].data,
        loading: false
      });
      document.querySelectorAll(".news_nav")[0].classList.add("active");
    });
  }

  open = (link) => {
    window.open(link, "articlepost");
  }


  render () {
    return (
      <div className="">
        {this.state.loading === true ?
          <div className="loading">
            <RingLoader
              sizeUnit={"px"}
              size={50}
              color={'#222F55'}
              loading={this.state.loading}
            />
          </div>
          :
          <div>
            <Fade>
              <div>
                <NavBar color='#FFFFFF'/>
                <SectionTitle title={this.state.articledetail.title}/>
                <div className="content_container">
                  <img alt="Article Banner" className="article_image" src={this.state.articledetail.image_url}/>
                  <p className="article_written_by">This article is written by {this.state.articledetail.writer}</p>
                  <Markdown markup={ this.state.articledetail.blog } />
                  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    {this.state.articledetail.pdf_url !== undefined ?
                      <div>
                        <div className="button job_button" onClick={() => this.open(this.state.articledetail.pdf_url)}>
                          <p className="button_text">Open PDF Version</p>
                        </div>
                      </div>
                      :
                      <div></div>
                    }
                  </div>
                  <p className="article_disclaimer">{this.state.articledetail.disclaimer}</p>
                </div>
                <Footer name={this.state.companyInfo.Name} locations={this.state.companyInfo.addresses} phones={this.state.companyInfo.phones} emails={this.state.companyInfo.emails} hours={this.state.companyInfo.office_hours} />
              </div>
            </Fade>
          </div>
        }
      </div>
    )
  }
}

export default ArticleDetail;
