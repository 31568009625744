import React, { Component } from 'react';
import {IndexLinkContainer} from 'react-router-bootstrap';
import Fade from 'react-reveal/Fade';

class Partners extends Component {
  format = (bar) => {
    var formattedString = bar.split("|").join("\n");
    return formattedString;
  }

  mailto = (email) => {
    var emailto = "mailto:" + email;
    window.open(emailto, "OpenMail");
  }

  render () {
    return (
      <Fade>
        <div className="content_container">
          <div className="partners_container">
            {this.props.partners.length > 0 && this.props.partners.map((partner, index) => (
              <IndexLinkContainer to={`/team/${partner._id}`} key={index} style={{cursor: 'pointer'}}>
                <div className="partner">
                  <div className="partner_image_name">
                    <div className="partner_image">
                      <img className="avatar" src={partner.image_url} alt={partner.name}/>
                    </div>
                    <div className="partner_name_container">
                      <p className="subheading">{partner.name} {partner.surname}</p>
                      <div className="partner_separator"></div>
                      <p className="bold bar_memberships">{this.format(partner.bar_memberships)}</p>
                      <div className="partner_button_tilt"></div>
                      <div className="partner_buttons">
                        <p className="view_profile_fade"></p>
                      </div>
                    </div>
                  </div>
                </div>
              </IndexLinkContainer>
            ))}
          </div>
        </div>
      </Fade>
    )
  }
}

export default Partners;
