import React, { Component } from 'react';
import axios from 'axios';
import RingLoader from 'react-spinners/RingLoader';
import Fade from 'react-reveal/Fade';
import NavBar from '../components/navbar';
import SectionTitle from '../components/sectiontitle';
import { Markdown } from 'react-showdown';
import Footer from '../components/footer';
import IndustriesSmall from '../components/industriessmall';

class IndustryDetail extends Component {
  state = {
    loading: true,
  }

  componentDidMount () {
    var string = this.props.location.pathname;
    var split = string.split("/");
    var id= split[split.length - 1];
    const articlelink = "https://cms-axis.herokuapp.com/industries/" + id;
    const industryPromise = axios.get('https://cms-axis.herokuapp.com/industries');

    const companyPromise = axios.get('https://cms-axis.herokuapp.com/companyinfos');
    const articlePromise = axios.get(articlelink);
    const parent = this;

    Promise.all([companyPromise, articlePromise, industryPromise]).then(function(values) {
      parent.setState({
        companyInfo: values[0].data[values[0].data.length-1],
        industrydetail: values[1].data,
        industries: values[2].data,
        loading: false
      });
      document.querySelectorAll(".cap_nav")[0].classList.add("active");
    });
  }

  componentDidUpdate () {
    var string = this.props.location.pathname;
    var split = string.split("/");
    var id= split[split.length - 1];
    const articlelink = "https://cms-axis.herokuapp.com/industries/" + id;
    const industryPromise = axios.get('https://cms-axis.herokuapp.com/industries');

    const companyPromise = axios.get('https://cms-axis.herokuapp.com/companyinfos');
    const articlePromise = axios.get(articlelink);
    const parent = this;

    Promise.all([companyPromise, articlePromise, industryPromise]).then(function(values) {
      parent.setState({
        companyInfo: values[0].data[values[0].data.length-1],
        industrydetail: values[1].data,
        industries: values[2].data,
        loading: false
      });
    });
  }

  render () {
    return (
      <div className="">
        {this.state.loading === true ?
          <div className="loading">
            <RingLoader
              sizeUnit={"px"}
              size={50}
              color={'#222F55'}
              loading={this.state.loading}
            />
          </div>
          :
          <div>
            <Fade>
              <div>
                <NavBar color='#FFFFFF'/>
                <SectionTitle title={this.state.industrydetail.industry}/>
                <IndustriesSmall industries={this.state.industries}/>
                <div className="content_container">
                  {this.state.industrydetail.description !== undefined ?
                    <Markdown markup={ this.state.industrydetail.description } />
                    :
                    <p>No description added for this Industry</p>
                  }
                </div>
                <Footer name={this.state.companyInfo.Name} locations={this.state.companyInfo.addresses} phones={this.state.companyInfo.phones} emails={this.state.companyInfo.emails} hours={this.state.companyInfo.office_hours} />
              </div>
            </Fade>
          </div>
        }
      </div>
    )
  }
}

export default IndustryDetail;
