import React, { Component } from 'react';
import {Carousel} from 'react-bootstrap';
import Fade from 'react-reveal/Fade';

class CareerPhotos extends Component {

  render () {
    return (
        <div className="career_photos_container">
          <div className="home_about_bg"></div>
          <div className="home_about_diag"></div>
          <div className="content_container photos_content">
            <div className="career_title_flex">
              <div className="career_title_container">
                <Fade delay={150}>
                  <p className="heading" style={{textAlign: 'left', marginBottom: '15px'}}>{this.props.title}</p>
                </Fade>
                <Fade delay={450}>
                  <p className="career_title_desc" style={{textAlign: 'left'}}>{this.props.description}</p>
                </Fade>
              </div>
            </div>
            <Fade delay={750}>
              <div className="career_title_slider">
                <Carousel fade={true}>
                  {this.props.photos.length > 0 && this.props.photos.map((photo, index) => (
                    <Carousel.Item className="slide_item" key={index}>
                      <img className="axis_office_image" src={photo.image_url} alt='Axis Office Space'/>
                    </Carousel.Item>
                  ))}
                </Carousel>
              </div>
            </Fade>
          </div>
        </div>
    )
  }
}

export default CareerPhotos;
