import React, { Component } from 'react';
import axios from 'axios';
import RingLoader from 'react-spinners/RingLoader';
import Fade from 'react-reveal/Fade';
import NavBar from '../components/navbar';
import SectionTitle from '../components/sectiontitle';
import TextContainer from '../components/text';
import Footer from '../components/footer';

class About extends Component {
  state = {
    loading: true,
  }

  componentDidMount () {
    const companyPromise = axios.get('https://cms-axis.herokuapp.com/companyinfos');
    const aboutPromise = axios.get('https://cms-axis.herokuapp.com/aboutsections?_sort=createdAt:desc');

    const parent = this;

    Promise.all([companyPromise, aboutPromise]).then(function(values) {
      console.log(values);
      parent.setState({
        companyInfo: values[0].data[values[0].data.length-1],
        about: values[1].data,
        loading: false
      })
    });
  }

  render () {
    return (
      <div>
        {this.state.loading === true ?
          <div className="loading">
            <RingLoader
              sizeUnit={"px"}
              size={50}
              color={'#222F55'}
              loading={this.state.loading}
            />
          </div>
          :
          <div>
            <Fade>
              <div>
                <NavBar color='#FFFFFF' textcolor='#777' navitems={['ABOUT US', 'SERVICES', 'PROJECTS', 'OUR TEAM']}/>
                <SectionTitle title="The Firm"/>
                {this.state.about.length > 0 && this.state.about.map((section, index) => (
                  <div className="about_text_section" key={index}>
                    <TextContainer title={section.title} paragraphs={section.paragraphs} image={section.pro_bono_section_image_url} links={section.internationallinks} markdown={section.desc}/>
                  </div>
                ))}
                <Footer name={this.state.companyInfo.Name} locations={this.state.companyInfo.addresses} phones={this.state.companyInfo.phones} emails={this.state.companyInfo.emails} hours={this.state.companyInfo.office_hours} />
              </div>
            </Fade>
          </div>
        }
      </div>
    )
  }
}

export default About;
