import React, { Component } from 'react';
import { AiFillRightCircle } from 'react-icons/ai';
import Fade from 'react-reveal/Fade';
import {IndexLinkContainer} from 'react-router-bootstrap';

class IndustriesSmall extends Component {
  render () {
    return (
      <div className="content_container">
        <p className="smallheading">Browse Other Industries</p>
        <div className="areas_container small_industry_container">
          {this.props.industries.length > 0 && this.props.industries.map((industry, index) => (
            <Fade down key={index}>
              <div className="area industry industry_smaller" key={index}>
                <IndexLinkContainer to={`/industries/${industry._id}`}>
                  <p><AiFillRightCircle/> {industry.industry}</p>
                </IndexLinkContainer>
              </div>
            </Fade>
          ))}
        </div>
      </div>
    )
  }
}

export default IndustriesSmall;
